import React from 'react';
import { useHistory } from 'react-router-dom';

export default (props) => {
  let history = useHistory();

  return (
    <nav className='bg-white px-6 py-5 border border-t-0 border-r-0 border-l-0'>
      <div className='container mx-auto max-w-screen-xl flex items-center justify-between flex-wrap'>
        <div className='flex items-center flex-shrink-0 text-black mr-6'>
          <img
            src='/images/logo-icon.png'
            alt='Halal hrana logo'
            className='cursor-pointer mr-1'
            onClick={() => history.push('/')}
            width="32px"
          />
          <img
            src='/images/logo.png'
            alt='Halal hrana logo'
            className='cursor-pointer'
            onClick={() => history.push('/')}
          />
        </div>
      </div>
    </nav>
  );
};
