const setSearchQuery = (query) => {
    localStorage.setItem('_c_search_query', query);
}

const getSearchQuery = () => {
    return localStorage.getItem('_c_search_query');
}

const removeSearchQuery = (query) => {
    localStorage.removeItem('_c_search_query');
}

export default {
    setSearchQuery,
    getSearchQuery,
    removeSearchQuery
}