import React from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Navbar from 'layout/navbar';
import Footer from 'layout/footer';
import Places from 'pages/places';
import Place from 'pages/place';
import Zasto from 'pages/zasto';
import Kako from 'pages/kako';
import NovaLokacija from 'pages/nova-lokacija';
import Marketing from 'pages/marketing';
import NotFound from 'pages/notfound';
import Mesnica from 'pages/mesnica';
import Mesnice from 'pages/mesnice';

import UserContext from 'context/user';
import AuthSvc from 'service/auth';

class App extends React.Component {
  state = {
    user: !AuthSvc.isAccessTokenValid()
      ? {}
      : AuthSvc.getUser()
  }

  componentDidMount() {
    if (!AuthSvc.isAccessTokenValid()) {
      AuthSvc.signOut();
    }
  }

  signOut = () => {
    this.setState({user: {}});
    AuthSvc.signOut();
    window.location = '/';
  }

  setUser = (user) => {
    this.setState({user});
    AuthSvc.setUser(user);
  }

  render() {
    return (
      <div className="App flex flex-col min-h-screen">
        <UserContext.Provider
          value={{
          user: this.state.user,
          signOut: this.signOut,
          setUser: this.setUser
        }}>
          <Router>
            <Navbar/>

            <div className="flex-grow">
              <Switch>
                <Route exact path="/" component={Places}/>
                <Route exact path="/p/:id" component={Place}/>
                <Route exact path="/dobavljaci" component={Mesnice}/>
                <Route exact path="/dobavljaci/:id" component={Mesnica}/>
                <Route exact path="/zasto" component={Zasto}/>
                <Route exact path="/kako" component={Kako}/>
                <Route exact path="/nova-lokacija" component={NovaLokacija}/>
                <Route exact path="/marketing" component={Marketing}/>
                <Route component={NotFound}/>
              </Switch>
            </div>

            <Footer/>
          </Router>
        </UserContext.Provider>
      </div>
    );
  }
}

export default App;
