import React, { Component } from 'react';
import DebounceInput from 'react-debounce-input';

import PlacesUtil from 'util/places';
import PlacesSvc from 'service/places';
import { getTopicsObjects } from 'util/topics';
import Modal from 'components/modal';
import TopicsPills from 'components/topicspills';
import CitiesFilter from 'components/citiesfilter';

const _ALL_TOPICS_ = getTopicsObjects();

// Place types can be fetched from the API "v1/places/types"
const _ALL_TYPES_ = [
  {
    type: 'restaurant',
    name: 'Restoran',
    selected: false,
  },
  {
    type: 'fast-food',
    name: 'Fast Food',
    selected: false,
  },
  {
    type: 'bakery',
    name: 'Pekara',
    selected: false,
  },
  {
    type: 'buregdzinica',
    name: 'Buregdžinica',
    selected: false,
  },
  {
    type: 'cevabdzinica',
    name: 'Ćevabdžinica',
    selected: false,
  },
  {
    type: 'ascinica',
    name: 'Aščinica',
    selected: false,
  },
];

class PlaceFilter extends Component {
  state = {
    allTopics: [],
    allTypes: [],
    filters: {
      searchTerm: '',
      selectedTopics: [],
      selectedTypes: [],
    },
    isTopicsModalVisible: false,
  };

  componentDidMount() {
    this.setup();
  }

  setup = () => {
    const urlTopics = PlacesUtil.getTopicsFromURL();
    let urlTypes = PlacesUtil.getTypesFromURL();
    let types = [];

    if (urlTypes) {
      if (urlTypes.indexOf(',') !== -1) {
        types = urlTypes.split(',');
      } else {
        types = [urlTypes];
      }
    }

    const _topics = setSelectedTopics(_ALL_TOPICS_, urlTopics);
    const _types = setSelectedTypes(_ALL_TYPES_, types);
    const _searchTerm = PlacesUtil.getSearchTermFromURL();
    const _filters = constructFilters(_topics, _types, _searchTerm);

    this.setState(
      {
        allTopics: _topics,
        allTypes: _types,
        filters: _filters,
      },
      () => {
        this.props.onSubmit(_filters);
      }
    );
  };

  handleSearchTermChange = (e) => {
    let filters = this.state.filters;
    filters.searchTerm = e.target.value;
    this.setState({ filters });
  };

  handleSearchTermKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.handleSearch();
    }
  };

  handleShowTopicsModal = () => {
    this.setState({ isTopicsModalVisible: true });
  };

  handleHideTopicsModal = () => {
    this.setState({ isTopicsModalVisible: false });
  };

  handleTopicsChange = (isChecked, topic) => {
    let allTopics = this.state.allTopics;
    allTopics[allTopics.findIndex((t) => t.field === topic.field)].selected =
      isChecked;

    this.setState({ allTopics });
  };

  handleTypesChange = (isChecked, type) => {
    let allTypes = this.state.allTypes;
    allTypes[allTypes.findIndex((t) => t.type === type.type)].selected =
      isChecked;

    this.setState({ allTypes });
  };

  handleSearch = () => {
    this.setState({ page: 0 });

    let s = this.state.filters.searchTerm
      ? '&page=1&s=' + this.state.filters.searchTerm
      : '';

    let query = '';
    let topicList = this.state.allTopics.filter((t) => t.selected);

    if (topicList?.length) {
      topicList.map((t) => {
        query += `&${t.field}=true`;
        return null;
      });
    }

    let typeList = this.state.allTypes
      .filter((t) => t.selected)
      .map((t) => t.type);
    if (typeList?.length) {
      query += `&type=${typeList}`;
    }

    if (query) {
      s += query;
    }

    PlacesSvc.setSearchQuery(s);
    let sq = s ? `?${s}` : '';
    window.location.replace('/' + sq);
  };

  handleResetFilters = () => {
    PlacesSvc.removeSearchQuery();
    window.location.replace('/');
  };

  render() {
    const selectedTopics = this.state.allTopics.filter((t) => t.selected);
    const topicsFilterColor =
      selectedTopics && selectedTopics.length > 0
        ? 'border-green-600 text-green-700'
        : 'border-gray-400';

    const topicsFilterLabel =
      selectedTopics && selectedTopics.length > 0
        ? getTopicsLabel(selectedTopics)
        : 'Filteri';

    return (
      <div className='mb-3'>
        <DebounceInput
          type='text'
          placeholder='Pretraga po nazivu objekta...'
          minLength={2}
          value={this.state.filters.searchTerm}
          onChange={this.handleSearchTermChange}
          onKeyPress={this.handleSearchTermKeyPress}
          debounceTimeout={300}
          className='search-text-input border-gray-400 rounded border py-2 px-4 mb-2 xl:mb-0 md:mr-1  focus:outline-none'
          style={{
            width: '416px',
            maxWidth: '100%',
          }}
        />

        {this.props.showFilters && (
          <React.Fragment>
            <button
              className={`w-full md:w-48 lg:w-56 rounded border py-2 px-4 mb-2 xl:mb-0 md:mr-1 focus:outline-none ${topicsFilterColor}`}
              onClick={this.handleShowTopicsModal}>
              {topicsFilterLabel}
            </button>

            <Modal
              visible={this.state.isTopicsModalVisible}
              onClickOutside={this.handleHideTopicsModal}
              onClose={this.handleHideTopicsModal}>
              <section>
                <h4 className='text-xl mb-0'>Filteri</h4>
                <TopicsPills
                  topics={_ALL_TOPICS_}
                  onClickTopic={this.handleTopicsChange}
                />

                <h4 className='text-xl mb-0 mt-4'>Lokacija</h4>
                <CitiesFilter />

                <h4 className='text-xl mb-0 mt-4'>Tip objekta</h4>
                <TopicsPills
                  topics={_ALL_TYPES_}
                  onClickTopic={this.handleTypesChange}
                />

                <div className="mt-10">
                  <button
                    className='w-full md:w-24 px-3 py-2 text-white bg-green-600 hover:bg-green-800 border rounded mb-2 xl:mb-0 md:mr-1 focus:outline-none'
                    onClick={this.handleSearch}>
                    Traži
                  </button>

                  <button
                    className='w-full md:w-24 px-3 py-2 text-green-600 hover:text-green-800 border border-green-600 hover:border-green-800 rounded focus:outline-none'
                    onClick={this.handleResetFilters}>
                    Reset
                  </button>
                </div>
              </section>
            </Modal>
          </React.Fragment>
        )}

        <button
          className='w-full md:w-24 px-3 py-2 text-white bg-green-600 hover:bg-green-800 border rounded mb-2 xl:mb-0 md:mr-1 focus:outline-none'
          onClick={this.handleSearch}>
          Traži
        </button>

        <button
          className='w-full md:w-24 px-3 py-2 text-green-600 hover:text-green-800 border border-green-600 hover:border-green-800 rounded focus:outline-none'
          onClick={this.handleResetFilters}>
          Reset
        </button>
      </div>
    );
  }
}

export default PlaceFilter;

const setSelectedTopics = (allTopics, urlTopics) => {
  if (!urlTopics || urlTopics.length === 0) {
    return allTopics;
  }

  allTopics.forEach((topic) => {
    if (urlTopics.find((urlTopic) => urlTopic === topic.field)) {
      topic.selected = true;
    }
  });

  return allTopics;
};

const setSelectedTypes = (allTypes, urlTypes) => {
  if (!urlTypes || urlTypes.length === 0) {
    return allTypes;
  }

  allTypes.map((type) => {
    if (urlTypes?.indexOf(type.type) !== -1) {
      type.selected = true;
    }
    return null;
  });

  return allTypes;
};

const getTopicsLabel = (topics) => {
  if (topics.length === 1) {
    return topics[0].name;
  }

  if (topics.length === 2) {
    return topics[0].name + ' i ' + topics[1].name;
  }

  return topics[0].name + ' i jos ' + (topics.length - 1);
};

const constructFilters = (topics, types, searchTerm) => {
  let f = {};

  if (topics?.length) {
    topics.map((t) => {
      if (t.selected) f[t.field] = true;
      return null;
    });
  }

  if (types?.length) {
    const _t = types.filter((t) => t.selected).map((t) => t.type);
    if (_t?.length) f.type = _t;
  }

  if (searchTerm) {
    f.searchTerm = searchTerm;
  }

  return f;
};
