import React from 'react'

export default () => {
    return (
        <div className="container max-w-xl mx-auto px-6 md:px-0 pt-4 md:pt-8 pb-16 md:pb-12">
            <h1 className="text-4xl font-extrabold mb-6">Dodavanje i izmjena lokacija</h1>
            <p>Dodavanje novih lokacija vrše administratori Halal Hrana stranice, shodno pouzdanim informacija s kojima raspolažu.</p><br />
            <p>Ukoliko želite predložiti dodavanje nove lokacije, ili imate drugačije informacije od onih koje su trenutno prikazane za neku lokacije, molimo Vas da nam se obratite na <a href="mailto:hranahalal@gmail.com">hranahalal@gmail.com</a>.</p>
        </div>
    )
}
