import React from "react";

export default () => {
  return (
    <div className="container max-w-xl mx-auto px-6 md:px-0 pt-4 md:pt-8 pb-16 md:pb-12">
      <h1 className="text-4xl font-extrabold mb-6">Zašto Halal Hrana?</h1>

      <p>
        Halal Hrana je osmišljen kao projekat koji bi muslimanima olakšao
        pronalazak halal hrane van njihovih domova.
      </p>
      <br />
      <p>
        Ponukani stalnim pitanjima: "Gdje jesti u tom i tom mjestu" i "Koje je
        meso u tom i tom objektu" uvidjeli smo potrebu za ovakvim jednim
        projektom.
      </p>
      <br />
      <p>
        Muslimanima je obaveza da jedu isključivo Halal Hranu, što je najčešće
        vezano za meso.
      </p>
      <h2 className="text-xl font-semibold my-3">Šta je halal hrana?</h2>
      <p>
        Pojam Halal na arapskom znači dozvoljeno, legalno. Ono što je halal je
        muslimanima dozvoljeno, što može označavati razne vrste radnji, poslova,
        veza, hrane i sl.
      </p>
      <br />
      <p>
        Haram je pojam obrnutog značenja od halala, i označava zabranjeno /
        ilegalno.
      </p>
      <br />
      <p>Halal hrana predstavlja svu onu hranu koja je u osnovi dozvoljena.</p>
      <p>
        Hrana poput žitarica, povrća, voća, mliječnih proizvoda i sl. je
        dozvoljena bez dodatnog uslovljavanja.
      </p>
      <br />
      <p>
        Strvina, svinjetina, alkohol (i sve ono što opija) je zabranjeno u
        osnovi (jedino je dozvoljeno u slučaju nužde, što nije tema ove
        stranice).
      </p>
      <br />
      <p>
        Hrana poput mesa je dozvoljena - halal, ukoliko se ispune uslovi za to.
      </p>
      <h2 className="text-xl font-semibold my-3">
        Uslovi da bi meso bilo halal
      </h2>
      <p>
        Da bi meso bilo halal, odnosno dozvoljeno za konzumiranje, ono mora
        zadovoljiti određene uvjete: Meso mora potjecati od životinje čije je
        meso dozvoljeno jesti prema islamskim propisima. To znači da je meso
        mesoždera i svaštojeda, poput vuka, medvjeda i lava, zabranjeno za
        konzumiranje. Također, meso svinje je strogo zabranjeno u islamu.
      </p>
      <p>
        <br />
        Životinja se mora zaklati na odgovarajući način, što znači da se mora
        zaklati nožem na način koji će izazvati što manje boli i stresa. Meso
        mora biti zaklano dok je još živo, što znači da se ne smije omamiti,
        ubiti strujom ili slično.
      </p>
      <p>
        <br/>
        Klanje životinje mora obaviti osoba koja prakticira islam ili pripadnik
        druge vjere (jevrej ili kršćanin) koji se pridržava vjerskih propisa u
        pogledu klanja životinja. Učestala je pojava da činu klanja pristupa
        svako, svejedno bio vjernik koji praktikuje islam ili samo deklarisani
        musliman koji nema ništa od islama. Zbog toga nam je cilj da uputima na
        meso koje su zaklali isključivo vjernici i u vezi s njim nema nedomumica
        ili sumnji.
      </p>
    </div>
  );
};
