import React, {useEffect, useRef} from 'react';

export default(props) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                props.onClickOutside();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [modalRef, props]);

    if (!props.visible) {
        return null;
    }

    return (
        <div className={`fixed inset-0 z-50 overflow-auto flex ${props.className}`}>
            <div className="bg-gray-700 opacity-75 absolute inset-0"></div>
            <div
                ref={modalRef}
                className={`relative p-8 bg-white w-full h-full md:h-auto rounded-none md:rounded md:max-w-md lg:max-w-lg xl:max-w-xl m-auto flex-col flex ${props.contentClassName}`}>
                <span className="absolute top-0 right-0 pin-r p-4" onClick={props.onClose}>
                    <svg
                        className="h-6 w-6 fill-current text-gray-700 hover:text-gray-800"
                        role="button"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20">
                        <title>Close</title>
                        <path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>

                {props.children}
            </div>
        </div>
    );
}