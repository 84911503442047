import React from 'react';

const imgs = {
  'fast-food': '/images/fast-food.jpeg',
  'restaurant': '/images/restaurant.jpeg',
  'hotel': '/images/hotel.jpeg',
  'bakery': '/images/pekara.jpg',
  'buregdzinica': '/images/buregdzinica.jpg',
  'cevabdzinica': '/images/cevabdzinica.jpg',
}

const PlaceImg = ({ place, className }) => {
  let placeImg = place?.image || place?.logo;
  if (!placeImg) {
    placeImg = place.type ? imgs[place.type] : imgs['restaurant'];
  }

  return (
    <div
      className={className}
      style={{
        backgroundImage: `url(${placeImg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
};

export default PlaceImg;
