import React, { useState, useEffect } from "react";
import cogoToast from "cogo-toast";
import moment from "moment";

import PlaceImg from "components/placeimg";
import PlaceCard from "components/placecard";
import PlacesAPI from "api/places";

const typeNames = {
  "fast-food": "Fast Food ",
  restaurant: "Restoran ",
  hotel: "Hotel ",
  bakery: "Pekara ",
  buregdzinica: "Buregdžinica ",
  cevabdzinica: "Ćevabdžinica ",
  ascinica: "Aščinica ",
};

export default (props) => {
  const [placeDetail, setPlaceDetail] = useState(null);
  const [suggested, setSuggested] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getPlace = async (ID) => {
      setIsLoading(true);
      try {
        let res = await PlacesAPI.detail(ID);
        const e = res ? res.data : null;
        if (e.ad_url) {
          window.location = `/`
          return;
        }
        setPlaceDetail(e);
        document.title = `${typeNames[e.type]} ${e.name} - Halal Hrana`;
      } catch (err) {
        cogoToast.error("Desio se problem prilikom dobavljanja objekta.");
      } finally {
        setIsLoading(false);
      }
    };

    const getSuggested = async (ID) => {
      try {
        let res = await PlacesAPI.suggested(ID, 3);
        const e = res ? res.data : null;
        setSuggested(e);
      } catch (err) {
        cogoToast.error(
          "Desio se problem prilikom dobavljanja objekata u blizini."
        );
      }
    };

    getPlace(props.match.params.id);
    getSuggested(props.match.params.id);
  }, [props.match.params.id]);

  const hasUpdates = placeDetail?.update_details?.length;

  const gMapsSrc = placeDetail?.address
    ? `https://maps.google.com/maps?q=${placeDetail.address}&t=&z=13&ie=UTF8&iwloc=&output=embed`
    : "";

  const formatDate = (date) => moment(date).format("DD. MM. YYYY. HH:mm");

  const getMeatTypePill = (meatDetail, type) => {
    if (!meatDetail || !meatDetail?.[0]?.name) {
      return "";
    }

    const butchers = [];

    for (const butcher of meatDetail) {
      const className = `butcher px-2 py-1 ${butcher?.status === "haram" ? "" : "halal bg-green-500"
        }`;
      butchers.push(
        <a
          className={className}
          key={butcher?.name}
          href={`/dobavljaci/${butcher?.id}`}
        >
          {butcher?.name}
        </a>
      );
    }

    return (
      <div className="meat-type">
        <label>{type}</label>
        <div className="butchers">{butchers}</div>
      </div>
    );
  };

  return (
    <div>
      <div className="mt-6 mb-6 px-4 xl:px-0">
        {isLoading && (
          <p className="text-center mt-12 text-lg font-light text-gray-600">
            Učitavanje objekata ...
          </p>
        )}

        {!isLoading && !placeDetail && (
          <p className="text-center mt-12 text-lg font-light text-gray-800">
            Desio se problem prilikom dobavljanja objekta.
          </p>
        )}

        {!isLoading && placeDetail && (
          <React.Fragment>
            <section className="flex flex-col md:flex-col">
              <div className="container mx-auto max-w-screen-xl border rounded p-4 md:pr-24 shadow-md">
                <PlaceImg
                  place={placeDetail}
                  className="h-64 rounded-md md:hidden mt-3"
                />

                <div
                  className="grid grid-cols-1 md:grid-cols-event-detail gap-3"
                  style={{
                    marginBottom: "6px",
                  }}
                >
                  <PlaceImg
                    place={placeDetail}
                    className="h-64 rounded-md hidden md:inline-block mt-3"
                  />

                  <section className="md:pl-3">
                    <h1 className="text-gray-800 text-3xl font-extrabold md:font-bold mb-2">
                      {typeNames[placeDetail.type] + placeDetail.name}
                    </h1>
                    <div className="flex mt-2 flex-wrap">
                      {getMeatTypePill(
                        placeDetail.chicken_meat_details,
                        "Piletina"
                      )}
                      {getMeatTypePill(
                        placeDetail.lamb_meat_details,
                        "Janjetina"
                      )}
                      {getMeatTypePill(
                        placeDetail.veal_meat_details,
                        "Teletina"
                      )}
                      {getMeatTypePill(
                        placeDetail.beef_meat_details,
                        "Junetina"
                      )}
                      {getMeatTypePill(
                        placeDetail.salami_meat_details,
                        "Salama"
                      )}
                      {getMeatTypePill(
                        placeDetail.burger_meat_details,
                        "Hamburger"
                      )}
                      {getMeatTypePill(
                        placeDetail.kebab_meat_details,
                        "Ćevapi"
                      )}
                      {getMeatTypePill(
                        placeDetail.sajuk_meat_details,
                        "Sudžuka"
                      )}
                      {getMeatTypePill(
                        placeDetail.dried_meat_details,
                        "Suho Meso"
                      )}
                      {getMeatTypePill(
                        placeDetail.minced_meat_details,
                        "Faširano Meso"
                      )}
                      {getMeatTypePill(
                        placeDetail.pljeska_meat_details,
                        "Pljeskavica"
                      )}
                      {getMeatTypePill(
                        placeDetail.chicken_doner_status,
                        "Pileći doner"
                      )}
                      {getMeatTypePill(
                        placeDetail.beef_doner_status,
                        "Juneći doner"
                      )}
                    </div>
                    <div className="flex flex-wrap container mx-auto max-w-screen-xl mt-2 rounded">
                      {!placeDetail.alcohol_free && (
                        <span className="detail-pill inline-block bg-red-500 text-white mt-2 py-1 px-2 mr-1 rounded">
                          Alkohol
                        </span>
                      )}
                      {placeDetail.alcohol_free && (
                        <span className="detail-pill inline-block bg-green-500 text-white mt-2 py-1 px-2 mr-1 rounded">
                          Bez alkohola
                        </span>
                      )}
                      {!placeDetail.music_free && (
                        <span className="detail-pill inline-block bg-red-500 text-white mt-2 py-1 px-2 mr-1 rounded">
                          Muzika
                        </span>
                      )}
                      {placeDetail.segregated && (
                        <span className="detail-pill inline-block bg-green-500 text-white mt-2 py-1 px-2 mr-1 rounded">
                          Odvojeno sjedenje
                        </span>
                      )}
                      {placeDetail.other_food_available && (
                        <span className="detail-pill inline-block bg-green-500 text-white mt-2 py-1 px-2 mr-1 rounded">
                          Dostupna hrana bez mesa
                        </span>
                      )}
                    </div>
                    <p className="text-gray-600 text-1xl font-light mb-2 md:w-2/3 mt-2">
                      {placeDetail.description}
                    </p>
                  </section>
                </div>
              </div>

              <div className="container mx-auto max-w-screen-xl mt-2 border rounded p-4 shadow-md">
                <h4 className="text-2xl mb-2">Informacije</h4>

                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div>
                    {placeDetail.city && (
                      <div className="mb-2">
                        <strong className="mr-1">Grad</strong>{" "}
                        {placeDetail.city}
                      </div>
                    )}
                    {placeDetail.address && (
                      <div className="mb-2">
                        <strong className="mr-1">Adresa</strong>{" "}
                        {placeDetail.address}
                      </div>
                    )}
                    {placeDetail.phone && (
                      <div className="mb-2">
                        <strong className="mr-1">Telefon</strong>{" "}
                        <a
                          href={`tel:${placeDetail.phone}`}
                          alt="Broj telefona restorana"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="no-underline hover:underline text-blue-500"
                        >
                          {placeDetail.phone}
                        </a>
                      </div>
                    )}
                    {placeDetail.website && (
                      <div className="mb-2">
                        <strong className="mr-1">Website</strong>{" "}
                        <a
                          href={placeDetail.website}
                          alt="Website restorana"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="no-underline hover:underline text-blue-500"
                        >
                          {placeDetail.website}
                        </a>
                      </div>
                    )}
                    {placeDetail.notice && (
                      <div className="mb-2">
                        <strong className="mr-1">Napomena</strong>{" "}
                        {placeDetail.notice}
                      </div>
                    )}
                    {placeDetail.updated_at && (
                      <div className="mb-2">
                        <strong className="mr-1">Posljednje ažuriranje</strong>{" "}
                        {formatDate(placeDetail.updated_at)}
                      </div>
                    )}
                  </div>
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe
                        title="Map"
                        width="600"
                        height="400"
                        style={{
                          maxWidth: "100%",
                        }}
                        id="gmap_canvas"
                        src={gMapsSrc}
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>

              {hasUpdates && (
                <div className="container mx-auto max-w-screen-xl mt-2 border rounded p-4 pr-12 shadow-md">
                  <h4 className="text-2xl mb-2">Izmjene</h4>

                  {placeDetail.update_details.map((u) => (
                    <div
                      className="mb-2"
                      style={{ borderBottom: "1px solid #eee" }}
                    >
                      <strong className="mr-1">
                        {moment(u.timestamp).format("DD.MM.YYYY")}
                      </strong>{" "}
                      {u.comment}
                    </div>
                  ))}
                </div>
              )}
            </section>
          </React.Fragment>
        )}

        {suggested?.length && (
          <React.Fragment>
            <div className="container mx-auto max-w-screen-xl mt-5">
              <h4 className="text-2xl mb-2">Drugi objekti u ovom mjestu</h4>

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {suggested && suggested.length > 0 && (
                  <React.Fragment>
                    {suggested.map((ev, k) => (
                      <PlaceCard className="mb-3" key={k} data={ev} />
                    ))}
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
