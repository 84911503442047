import React from 'react';
import {hydrate, render} from 'react-dom';
import {loadProgressBar} from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';

import App from './App';
import AuthInterceptors from './util/authInterceptors';
import * as serviceWorker from './serviceWorker';
import './assets/main.css';

loadProgressBar({
    showSpinner: false,
    template: '<div class="bar" role="bar" style="height:5px; background-color: #48bb78;"><div ' +
            'class="peg"></div></div>'
});
AuthInterceptors.setup();

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
    hydrate(
        <App/>, rootElement);
} else {
    render(
        <App/>, rootElement);
}

serviceWorker.register();
