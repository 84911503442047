import React from 'react'

export default () => {
    return (
        <div className="container max-w-xl mx-auto px-6 md:px-0 pt-4 md:pt-8 pb-16 md:pb-12">
            <h1 className="text-4xl font-extrabold mb-6">Marketing na Halal Hrana</h1>
            <p>Halal Hrana služi kao baza podataka svih vrsta objekata koji nude hranu
                (restorani, pekare, ćevabžinice...) koja je u nekoj mjeri ili potpuno Halal.</p><br />
            <p>Posjetioci na našoj stranici tragaju za halal objektima, te je kao takva idealna za promociju Vašeg restorana, fast-fooda, mesnice ili nekog drugog halal proizvoda.</p><br />
            <p>Za vlasnike objekata koji su dostupni na našoj stranici, postoji mogućnost izdvajanja objekta (da bude uvijek na vrhu, sa drugačijim okvirom) na čitavoj stranici ili samo u Lokaciji.</p><br />
            <p>Osim izdvajanja objekata, u mogućnosti smo i da vam pružimo reklame na stranici i u našoj aplikaciji.</p><br />
            <p>Za sve detalje reklamiranja na Halal Hrana stranici, kontaktirajte nas putem email-a: <a href="mailto:hranahalal@gmail.com">hranahalal@gmail.com</a></p>
        </div>
    )
}
