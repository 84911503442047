import React from 'react';
import { useHistory } from 'react-router-dom';

import TelegramLogo from 'layout/telegram.svg';
import FacebookLogo from 'layout/facebook.svg';

export default () => {
  let history = useHistory();

  return (
    <footer className='bg-gray-200 px-6'>
      <div className='container mx-auto max-w-screen-xl bg-grey-lighter px-2 py-8 md:px-0'>
        <div className='sm:flex mb-4'>
          <div className='sm:1 md:w-1/3 h-auto'>
            <img
              src='/images/logo-icon.png'
              alt='Halal Hrana logo'
              className='cursor-pointer mr-1 inline-block'
              onClick={() => history.push('/')}
              width='32px'
            />
            <img
              src='/images/logo.png'
              alt='Halal Hrana logo'
              className='cursor-pointer inline-block'
              onClick={() => history.push('/')}
            />
            <p className='text-gray-700 text-xs mt-0 w-3/4'>
              Pouzdan izvor Halal Hrane
            </p>
          </div>
          <div className='sm:1 md:w-1/4 h-auto sm:mt-0 mt-8'>
            <div className='text-green-800 mb-1'>Halal Hrana</div>
            <ul className='list-reset leading-normal'>
              <li className='hover:text-green-800 text-grey-700 text-sm mb-1'>
                <a href='/zasto'>Zašto Halal Hrana</a>
              </li>
              <li className='hover:text-green-800 text-grey-700 text-sm mb-1'>
                <a href='/kako'>Kako radi Halal Hrana</a>
              </li>
              <li className='hover:text-green-800 text-grey-700 text-sm mb-1'>
                <a href='/nova-lokacija'>Dodavanje / izmjena lokacija</a>
              </li>
              <li className='hover:text-green-800 text-grey-700 text-sm mb-1'>
                <a href='/marketing'>Marketing</a>
              </li>
              <li className='hover:text-green-800 text-grey-700 text-sm mb-1'>
                <a href='/dobavljaci'>Dobavljači</a>
              </li>
            </ul>
          </div>
          <div className='sm:1 md:w-1/4 h-auto sm:mt-0 mt-8'>
            <div className='text-green-800 mb-1'>Kontakt</div>
            <ul className='list-reset leading-normal'>
              <li className='flex flex-col hover:text-green-800 text-grey-700 text-sm mb-1'>
                <a
                  href='https://t.me/halalhranacom'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src={TelegramLogo}
                    alt='Telegram Kanal'
                    className='inline-block mr-0 w-5'
                  />
                  Kanal
                </a>
              </li>
              <li className='flex flex-col hover:text-green-800 text-grey-700 text-sm mb-1'>
                <a
                  href='https://facebook.com/halalhranaDOTcom'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src={FacebookLogo}
                    alt='FacebookStranica'
                    className='inline-block mr-0 w-5'
                  />
                  Stranica
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};
