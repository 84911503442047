export const _TOPICS_ = {
  musicFree: {
    name: 'Bez muzike',
  },
  alcoholFree: {
    name: 'Bez alkohola',
  },
  segregated: {
    name: "Porodični kutak"
  }
};

export const getTopicsObjects = () =>
  Object.keys(_TOPICS_).map((t) => ({
    field: t,
    name: _TOPICS_[t].name,
    selected: false,
    type: _TOPICS_[t].type
  }));
