import React, { useEffect } from 'react';
import Select from 'react-select';
import CitiesAPI from 'api/cities';

import { useAtom } from 'jotai';
import { filterAtom } from '../store';
import eventBus from '../util/eventBus';

const CitiesFilter = () => {
	const [filter, setFilter] = useAtom(filterAtom);

	useEffect(() => {
		const fetchCities = async () => {
			const response = await CitiesAPI.list({});
			setFilter({
				...filter,
				cities: response.data.map(city => ({
					value: city,
					label: city
				}))
			});
		}

		if (!filter.cities.length) {
			fetchCities();
		}
	}, [filter, setFilter]);

	const onSelectItem = (event) => {
		const selectedCities = event.map(({ value }) => value);
		setFilter({
			...filter,
			selectedCities
		});

		eventBus.emit('citiesFilterUpdated', selectedCities);
	}

	return (
		<Select
			isMulti
			options={filter.cities}
			onChange={onSelectItem}
		/>
	)
}

export default CitiesFilter;
