import React, { Component } from 'react';
import cogoToast from 'cogo-toast';
import { Helmet } from 'react-helmet';

import MeatIndustryAPI from 'api/meat';
import PlaceCard from 'components/placecard';

class Mesnice extends Component {
	state = {
		mesnice: [],
		hasProblemLoading: false,
		isLoading: false,
		page: 1,
	};

	componentDidMount() {
		this.getMeatIndustries();
	}

	getMeatIndustries = async (_filters) => {
		this.setState({ isLoading: true });

		let filters = {
			..._filters,
			limit: 50,
			page: this.state.page
		};

		if (_filters?.searchTerm) {
			filters.name = _filters.searchTerm;
			delete filters.searchTerm;
		}

		let hasProblemLoading = false;

		try {
			let res = await MeatIndustryAPI.list(filters);
			let hasMorePlaces = true;

			if (!res.data) {
				hasMorePlaces = false;
			}

			let places = null;

			if (this.state.page === 1) {
				places = res.data;
			} else {
				places = this.state.places; //keep existing places from previous pages, so new ones can be concatenated
			}

			if (hasMorePlaces && this.state.page !== 1) {
				places = this.state.places.concat(res.data);
			}
			this.setState({ places, hasMorePlaces });
		} catch (err) {
			hasProblemLoading = true;
			cogoToast.error('Desio se problem prilikom dobavljanja mesnica.');
		}

		this.setState({
			isLoading: false,
			isLoadingMorePlaces: false,
			hasProblemLoading,
		});
	};

	render() {
		const { isLoading, hasProblemLoading, places } = this.state;

		return (
			<div>
				<Helmet>
					<title>Dobavljači - Halal Hrana</title>
				</Helmet>

				<div className='container w-11/12 mx-auto mt-4'>
					{/* Loading events */}
					{isLoading && (
						<p className='text-left mt-6 text-gray-700 font-bold'>
							Loading...
						</p>
					)}

					{/* Problem loading events */}
					{!isLoading && hasProblemLoading && (
						<div>
							<p className='text-left mt-6 text-gray-700 font-bold'>
								Desio se problem. Probajte ponovo.
							</p>
						</div>
					)}

					{/* No events found */}
					{!isLoading && (!places || places.length === 0) && (
						<div>
							<p className='text-left mt-6 text-gray-700 font-bold'>
								Nema mesnica za taj pojam.
							</p>
						</div>
					)}

					<div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
						{/* Events */}
						{!isLoading && places && places.length > 0 && (
							<React.Fragment>
								{places.map((ev, k) => (
									<PlaceCard className='mb-3' key={k} data={ev} type={'meat'} />
								))}
							</React.Fragment>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Mesnice;
