import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_ROOT;

const list = (params) => {
    const qp = params
        ? '?' + Object
            .keys(params)
            .map(key => key + '=' + params[key])
            .join('&')
        : '';
    return axios.get(`${API_BASE}/v1/places${qp}`);
}

const detail = (id) => {
    let URL = `${API_BASE}/v1/places/${id}`;
    return axios.get(URL);
}

const suggested = (id, suggestedCount) => {
    let URL = `${API_BASE}/v1/places/${id}/suggested`;
    if (suggestedCount) {
        URL = URL + '?count=' + suggestedCount
    }
    return axios.get(URL);
}

export default {
    list,
    detail,
    suggested
}