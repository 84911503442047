import axios from 'axios';
import cogoToast from 'cogo-toast';

import AuthAPI from 'api/auth';
import AuthSvc from 'service/auth';

export const setup = () => {
    axios
        .interceptors
        .response
        .use(res => {
            return res;
        }, err => {
            if (!err || (err.config.method === 'put' && err.config.url.includes('/v1/auth')) || !err.response || err.response.status !== 401) {
                // No response from server or refresh token is invalid, don't retry to refresh
                // again
                return Promise.reject(err);
            }

            return AuthAPI
                .refreshAccessToken({
                refresh_token: AuthSvc.getRefreshToken()
            })
                .then(res => {
                    AuthSvc.setAccessToken(res.data.access_token);

                    // Got the new token, now retry the original request with the new token
                    let originalRequest = err.config;
                    originalRequest.headers['Authorization'] = `Bearer ${res.data.access_token}`;

                    return new Promise((resolve, reject) => {
                        axios
                            .request(originalRequest)
                            .then(res => {
                                return resolve(res);
                            })
                            .catch(err => {
                                return reject(err);
                            });
                    });
                })
                .catch(err => {
                    if (err.config.method === 'put' && err.config.url.includes('/v1/auth')) {
                        // Refresh token request failed
                        AuthSvc.removeAccessToken();
                        AuthSvc.removeRefreshToken();
                        window.location = '/';
                        cogoToast.info('Your session expired');
                    }

                    return Promise.reject(err);
                });
        });
};

export default {
    setup
}
