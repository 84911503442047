import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_ROOT;

const list = (params) => {
	const qp = params
		? '?' + Object
		.keys(params)
		.map(key => key + '=' + params[key])
		.join('&')
		: '';
	return axios.get(`${API_BASE}/v1/meat_industries${qp}`);
}

const detail = (id) => {
	let URL = `${API_BASE}/v1/meat_industries/${id}`;
	return axios.get(URL);
}

export default {
	list,
	detail,
}
