import axios from 'axios';

const { REACT_APP_API_ROOT: API_BASE } = process.env;

const list = (params) => {
	const qp = params
		? '?' + Object
		.keys(params)
		.map(key => key + '=' + params[key])
		.join('&')
		: '';
	return axios.get(`${API_BASE}/v1/cities${qp}&used=true`);
};

export default {
	list
};
