import jwtDecode from 'jwt-decode';

const setAccessToken = (token) => {
    localStorage.setItem('_c_acc_token', token);
}

const getAccessToken = () => {
    return localStorage.getItem('_c_acc_token');
}

const removeAccessToken = (token) => {
    localStorage.removeItem('_c_acc_token');
}

const setRefreshToken = (token) => {
    localStorage.setItem('_c_ref_token', token);
}

const getRefreshToken = () => {
    return localStorage.getItem('_c_ref_token');
}

const removeRefreshToken = (token) => {
    localStorage.removeItem('_c_ref_token');
}

const isAccessTokenValid = () => {
    const t = getAccessToken();
    if(!t) {
        return false;
    }

    let decoded = jwtDecode(t)
    return new Date(decoded.exp * 1000) > new Date()
        ? true
        : false;
}

const setUser = (user) => {
    localStorage.setItem('_c_user', JSON.stringify(user));
}

const getUser = () => {
    return JSON.parse(localStorage.getItem('_c_user'));
}

const signOut = () => {
    removeAccessToken();
    removeRefreshToken();
    setUser({});
}

export default {
    setAccessToken,
    getAccessToken,
    removeAccessToken,
    setRefreshToken,
    getRefreshToken,
    removeRefreshToken,
    isAccessTokenValid,
    signOut,
    setUser,
    getUser
}