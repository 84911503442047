import React from 'react';

import PlaceImg from 'components/placeimg';

const typeNames = {
    'fast-food': 'Fast Food ',
    'restaurant': 'Restoran ',
    'hotel': 'Hotel ',
    'bakery': 'Pekara ',
    'buregdzinica': 'Buregdžinica ',
    'cevabdzinica': 'Ćevabdžinica ',
    'ascinica': "Aščinica ",
}

export default (props) => {
    const placeDetail = props.data;

    const trim = (v, limit) => {
        if (!v) return v;
        if (v.length <= limit) {
            return v;
        }

        return `${v.substr(0, limit)}...`;
    };

    const getHref = () => {
        if (placeDetail.ad_url) {
            return placeDetail.ad_url;
        }
        return props.type === 'meat' ? `/dobavljaci/${placeDetail.unique_name}` : `/p/${placeDetail.unique_name}`
    }
    return (
        <a href={getHref()}
            className={'rounded overflow-hidden shadow-lg cursor-pointer hover:shadow-xl transition duration-500 ease-in-out relative ' + props.className}
        >
            <PlaceImg place={placeDetail} className='w-full h-48' />
            <div className="px-6 pt-3 pb-5">
                <div className="font-bold text-xl">{(typeNames[placeDetail.type] || '') + trim(placeDetail.name, 40)}</div>
                <p className="text-gray-700 text-base">
                    {trim(placeDetail.description, 120)}
                </p>
            </div>
        </a>
    );
};
