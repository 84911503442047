import React from 'react';

const notfound = () => {
    return (
        <div className="container mx-auto pt-16 pb-16 md:pb-0 text-center">
            <h1 className="font-extrabold md:font-semibold text-3xl sm:text-5xl mb-6">404 - Stranica nije pronađena</h1>
            <a
                href="/"
                className="text-xl text-green-700 hover:text-green-900 underline">Početna stranica</a>
        </div>
    );
};

export default notfound;