import { _TOPICS_ } from 'util/topics';

const _T_ = ['musicFree', 'alcoholFree', "segregated"];

const getTopicsFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  let ts = [];
  _T_.map((t) => {
    const p = urlParams.get(t);
    if (p) {
      ts.push(t);
    }
    return null;
  });

  return ts;
};

const getTypesFromURL = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('type');
}

const constructTopics = (urlTopics) => {
  urlTopics = urlTopics.map((t) => t.toUpperCase());

  let topics = [];
  // eslint-disable-next-line
  _TOPICS_.map((t) => {
    // eslint-disable-next-line
    if (urlTopics.includes(t.toUpperCase())) {
      return topics.push(t);
    }
  });

  return topics;
};

const getSearchTermFromURL = () => {
  var urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('s') || '';
};

export default {
  getTopicsFromURL,
  constructTopics,
  getTypesFromURL,
  getSearchTermFromURL
};