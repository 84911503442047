import axios from 'axios';

import AuthSvc from './../service/auth';

const API_BASE = process.env.REACT_APP_API_ROOT;

const getConfig = () => ({
    headers: {
        Authorization: `Bearer ${AuthSvc.getAccessToken()}`
    }
});

const GitHubLogin = () => {
    return axios.post(API_BASE + '/v1/auth/github/login');
}

const GitHubCodeLogin = (data) => {
    return axios.post(API_BASE + '/v1/auth/github', data);
}

const fetchInfo = () => {
    return axios.get(API_BASE + '/v1/auth/me', getConfig());
}

const refreshAccessToken = (data) => {
    return axios.put(API_BASE + '/v1/auth', data);
}

export default {
    GitHubLogin,
    GitHubCodeLogin,
    fetchInfo,
    refreshAccessToken
}