import React from 'react'

export default({topics, onClickTopic}) => {
    return (
        <React.Fragment>
            {topics.map((topic, key) => {
                return <label
                    key={key}
                    className={`inline-block mb-1 rounded-full px-5 py-1 text-base text-center cursor-pointer mt-3 mr-3 ${topic.selected
                    ? 'bg-green-600 hover:bg-green-700 text-white'
                    : 'bg-gray-200 hover:bg-gray-300 text-gray-800'}`}>
                    <input
                        type="checkbox"
                        className="invisible w-0"
                        checked={topic.selected}
                        onChange={(e) => onClickTopic(e.target.checked, topic)}></input>
                    {topic.name}
                </label>
            })}
        </React.Fragment>
    );
}
