import React, { useState, useEffect } from "react";
import cogoToast from "cogo-toast";
import moment from "moment";

import PlaceImg from "components/placeimg";
import MeatIndustryAPI from "api/meat";

export default (props) => {
  const [placeDetail, setPlaceDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getMeatIndustry = async (ID) => {
      setIsLoading(true);
      try {
        let res = await MeatIndustryAPI.detail(ID);
        const e = res ? res.data : null;
        setPlaceDetail(e);
        document.title = `${e.name} - Halal Hrana`;
      } catch (err) {
        cogoToast.error("Desio se problem prilikom dobavljanja objekta.");
      } finally {
        setIsLoading(false);
      }
    };

    getMeatIndustry(props.match.params.id);
  }, [props.match.params.id]);

  const gMapsSrc = placeDetail?.address
    ? `https://maps.google.com/maps?q=${placeDetail.address}&t=&z=13&ie=UTF8&iwloc=&output=embed`
    : "";

  const formatDate = (date) => moment(date).format("DD. MM. YYYY. HH:mm");

  const getMeatTypePill = (status, type) => {
    if (!status || status !== "halal") {
      return "";
    }

    const className = `butcher px-2 py-1 halal bg-green-500`;

    return (
      <div className="meat-type">
        <label>{type}</label>
        <div className="butchers">
          <span className={className}>Halal</span>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="mt-6 mb-6 px-4 xl:px-0">
        {isLoading && (
          <p className="text-center mt-12 text-lg font-light text-gray-600">
            Učitavanje dobavljača ...
          </p>
        )}

        {!isLoading && !placeDetail && (
          <p className="text-center mt-12 text-lg font-light text-gray-800">
            Desio se problem prilikom dobavljanja podataka od dobavljača.
          </p>
        )}

        {!isLoading && placeDetail && (
          <React.Fragment>
            <section className="flex flex-col md:flex-col">
              <div className="container mx-auto max-w-screen-xl border rounded p-4 md:pr-24 shadow-md">
                <PlaceImg
                  place={placeDetail}
                  className="h-64 rounded-md md:hidden mt-3"
                />

                <div
                  className="grid grid-cols-1 md:grid-cols-event-detail gap-3"
                  style={{
                    marginBottom: "6px",
                  }}
                >
                  <PlaceImg
                    place={placeDetail}
                    className="h-64 rounded-md hidden md:inline-block mt-3"
                  />

                  <section className="md:pl-3">
                    <h1 className="text-gray-800 text-3xl font-extrabold md:font-bold mb-2">
                      {placeDetail.name}
                    </h1>
                    <div className="flex mt-2 flex-wrap">
                      {getMeatTypePill(placeDetail.chicken_status, "Piletina")}
                      {getMeatTypePill(placeDetail.lamb_status, "Janjetina")}
                      {getMeatTypePill(placeDetail.veal_status, "Teletina")}
                      {getMeatTypePill(placeDetail.beef_status, "Junetina")}
                      {getMeatTypePill(placeDetail.salami_status, "Salama")}
                      {getMeatTypePill(placeDetail.burger_status, "Hamburger")}
                      {getMeatTypePill(placeDetail.kebab_status, "Ćevapi")}
                      {getMeatTypePill(placeDetail.sajuk_status, "Sudžuka")}
                      {getMeatTypePill(
                        placeDetail.dried_meat_details,
                        "Suho Meso"
                      )}
                      {getMeatTypePill(
                        placeDetail.minced_meat_details,
                        "Faširano Meso"
                      )}
                      {getMeatTypePill(
                        placeDetail.pljeska_status,
                        "Pljeskavica"
                      )}
                      {getMeatTypePill(
                        placeDetail.chicken_doner_status,
                        "Pileći doner"
                      )}
                      {getMeatTypePill(
                        placeDetail.beef_doner_status,
                        "Juneći doner"
                      )}
                    </div>
                    <p className="text-gray-600 text-1xl font-light mb-2 md:w-2/3 mt-2">
                      {placeDetail.description}
                    </p>
                  </section>
                </div>
              </div>

              <div className="container mx-auto max-w-screen-xl mt-2 border rounded p-4 shadow-md">
                <h4 className="text-2xl mb-2">Informacije</h4>

                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div>
                    {placeDetail.city && (
                      <div className="mb-2">
                        <strong className="mr-1">Grad</strong>{" "}
                        {placeDetail.city}
                      </div>
                    )}
                    {placeDetail.address && (
                      <div className="mb-2">
                        <strong className="mr-1">Adresa</strong>{" "}
                        {placeDetail.address}
                      </div>
                    )}
                    {placeDetail.phone && (
                      <div className="mb-2">
                        <strong className="mr-1">Telefon</strong>{" "}
                        <a
                          href={`tel:${placeDetail.phone}`}
                          alt="Broj telefona restorana"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="no-underline hover:underline text-blue-500"
                        >
                          {placeDetail.phone}
                        </a>
                      </div>
                    )}
                    {placeDetail.website && (
                      <div className="mb-2">
                        <strong className="mr-1">Website</strong>{" "}
                        <a
                          href={placeDetail.website}
                          alt="Website restorana"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="no-underline hover:underline text-blue-500"
                        >
                          {placeDetail.website}
                        </a>
                      </div>
                    )}
                    {placeDetail.updated_at && (
                      <div className="mb-2">
                        <strong className="mr-1">Posljednje ažuriranje</strong>{" "}
                        {formatDate(placeDetail.updated_at)}
                      </div>
                    )}
                  </div>
                  <div className="mapouter">
                    <div className="gmap_canvas">
                      <iframe
                        title="Map"
                        width="600"
                        height="400"
                        style={{
                          maxWidth: "100%",
                        }}
                        id="gmap_canvas"
                        src={gMapsSrc}
                        frameBorder="0"
                        scrolling="no"
                        marginHeight="0"
                        marginWidth="0"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};
