import React, { Component } from 'react';
import cogoToast from 'cogo-toast';
import { Helmet } from 'react-helmet';

import PlacesAPI from 'api/places';
import PlaceCard from 'components/placecard';
import PlaceFilter from 'components/placefilter';
import eventBus from '../util/eventBus';

let _filtersCache = null;

class Places extends Component {
  state = {
    places: [],
    hasMorePlaces: true,
    hasProblemLoadingPlaces: false,
    isLoadingPlaces: false,
    isLoadingMorePlaces: false,
    isTopicsDropdownOpen: false,
    page: 1,
  };

  componentDidMount() {
    eventBus.on('citiesFilterUpdated', cities => {
      this.getPlaces({
        cities: cities.map(city => encodeURIComponent(city)).join(',')
      });
    });
  }

  componentWillUnmount() {
    eventBus.off('citiesFilterUpdated');
  }

  getPlaces = async (_filters) => {
    _filtersCache = _filters;

    if (!this.state.places || this.state.places.length === 0) {
      this.setState({ isLoadingPlaces: true });
    } else {
      this.setState({ isLoadingMorePlaces: true });
    }

    let filters = {
      ..._filters,
      limit: 50,
      page: this.state.page,
      embed: 'mi'
    };

    if (_filters?.searchTerm) {
      filters.name = _filters.searchTerm;
      delete filters.searchTerm;
    }

    let hasProblemLoadingPlaces = false;

    try {
      let res = await PlacesAPI.list(filters);
      let hasMorePlaces = true;

      if (!res.data) {
        hasMorePlaces = false;
      }

      let places = null;

      if (this.state.page === 1) {
        places = res.data;
      } else {
        places = this.state.places; //keep existing places from previous pages, so new ones can be concatenated
      }

      if (hasMorePlaces && this.state.page !== 1) {
        places = this.state.places.concat(res.data);
      }
      this.setState({ places, hasMorePlaces });
    } catch (err) {
      hasProblemLoadingPlaces = true;
      cogoToast.error('Desio se problem prilikom dobavljanja objekata');
    }

    this.setState({
      isLoadingPlaces: false,
      isLoadingMorePlaces: false,
      hasProblemLoadingPlaces,
    });
  };

  handleLoadMore = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => {
        this.getPlaces(_filtersCache);
      }
    );
  };

  render() {
    const { isLoadingPlaces, hasProblemLoadingPlaces, places } = this.state;

    return (
      <div>
        <Helmet>
          <title>Halal Hrana</title>
        </Helmet>

        <div className='container w-11/12 mx-auto mt-4'>
          <PlaceFilter onSubmit={this.getPlaces} showFilters />

          {/* Loading events */}
          {isLoadingPlaces && (
            <p className='text-left mt-6 text-gray-700 font-bold'>
              Loading...
            </p>
          )}

          {/* Problem loading events */}
          {!isLoadingPlaces && hasProblemLoadingPlaces && (
            <div>
              <p className='text-left mt-6 text-gray-700 font-bold'>
                Desio se problem. Probajte ponovo.
              </p>
            </div>
          )}

          {/* No events found */}
          {!isLoadingPlaces && (!places || places.length === 0) && (
            <div>
              <p className='text-left mt-6 text-gray-700 font-bold'>
                Nema objekata za taj pojam.
              </p>
            </div>
          )}

          <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
            {/* Events */}
            {!isLoadingPlaces && places && places.length > 0 && (
              <React.Fragment>
                {places.map((ev, k) => (
                  <PlaceCard className='mb-3' key={k} data={ev} />
                ))}
              </React.Fragment>
            )}
          </div>

          {/* Load more events */}
          {/* {(!this.state.isLoadingPlaces && this.state.places && this.state.places.length > 0) && <div className="text-center my-10"> */}
          {/* {!this.state.hasMorePlaces && <p className="text-center text-gray-600">Kraj liste</p>} */}

          {/* {this.state.hasMorePlaces && <button
                            className="inline-block items-center px-6 py-2 border rounded-full text-green-600 border-teal-600 hover:text-green-800 hover:border-green-800 focus:outline-none"
                            onClick={this.handleLoadMore}>
                            Ucitaj jos
                        </button>
} */}
          {/* </div>} */}
        </div>
      </div>
    );
  }
}

export default Places;
