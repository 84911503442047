import React from "react";

export default () => {
  return (
    <div className="container max-w-xl mx-auto px-6 md:px-0 pt-4 md:pt-8 pb-16 md:pb-12">
      <h1 className="text-4xl font-extrabold mb-6">Kako radi Halal Hrana?</h1>
      <p>
        Halal Hrana je osmišljen kao projekat kako bi vjernicama olakšao
        pronalazak Halal Hrana van svoje kuće.
      </p>
      <br />
      <p>
        Stranica sadrži listu objekata - restorana, pekara, fast food-ova,
        ćevabdžinica i buregdžinica.
      </p>
      <br/>
      <p>
        Koristeći filtere na vrhu stranice, objekte je moguće filtrirati po:
      </p>
      <br />
      <img src="/images/filteri.png" alt="Filteri na vrhu stranice" />
      <br />
      <ul type="i">
        <li>Imenu</li>
        <li>Gradu ili opštini</li>
        <li>Tip objekta</li>
        <li>Da li služe alkohol ili ne</li>
        <li>Da li puštaju muziku</li>
        <li>
          Da li imaju separe-e gdje se može odvojiti sa porodicom od ostalih
        </li>
      </ul>
      <br />
      <img src="/images/filteri-detaljno.png" alt="Filteri detaljno" />
      <br />
      <p>
        Nakon što filtrirate objekte i izaberete neki koji vas zanima, bit će
        vam prikazano
      </p>
      <ul type="i">
        <li>Ime i kratko opis objekta</li>
        <li>Sve vrste mesa koje ima u ponudi, i njihov status</li>
        <ul type="i">
          <li>Ukoliko je meso halal, bit će označeno zelenom bojom</li>
          <li>
            Ukoliko je meso haram ili neprovjereno, bit će označeno sivom bojom
          </li>
        </ul>
        <li>Da li služe alkohol ili ne</li>
        <li>Da li ima muzike ili ne</li>
        <li>
          Da li ima hrana koja je u osnovi halal - bez mesa (pice, tjestenina i
          sl.)
        </li>
        <li>
          Da li imaju separe-e gdje se može odvojiti sa porodicom od ostalih
        </li>
        <li>
          Informacije o objektu (grad, adresa, lokacija, broj telefona ...)
        </li>
        <li>Drugi objekti u istoj opštini / gradu</li>
      </ul>
      <br />
      <h2 className="text-xl font-semibold my-3">
        Telegram notifikacija i chat
      </h2>
      <p>
        Halal Hrana putem{" "}
        <a href="https://t.me/halalhranacom">Telegram Kanala</a> šalje
        automatske obavijesti za nove i izmjene na objektima.
      </p>
      <br />
      <p>
        Pored Telegram kanala, dostupan je i{" "}
        <a href="Telegram ChatChat">Chat</a> za razmjenu informacija.
      </p>
    </div>
  );
};
